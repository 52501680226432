const projectsListFr = [
    {
        img: 'project_emotors.jpg',
        title: 'E-motors',
        description: "Site e-commerce d'un mandataire automobile",
        tasks: [
            'Maintenance et optimisation front',
            'Evolution front'
        ],
        technologies: [
            'html',
            'css',
            'Sass',
            'Bootstrap',
            'Jquery',
            'Twig'
        ]
    },
    {
        img: 'project_borne.jpg',
        title: 'Borne interactive',
        description: "Application pour pré-commander des voitures",
        tasks: [
            'Integration front',
        ],
        technologies: [
            'html',
            'Sass',
            'Bootstrap',
            'Angular'
        ]
    },
    {
        img: 'project_minisite.jpg',
        title: 'Générateur de template',
        description: "Site vitrine d'un outil de générateur de templates",
        tasks: [
            'Integration front',
        ],
        technologies: [
            'html',
            'Sass',
            'Bootstrap',
            'Gatsby'
        ]
    },
    {
        img: 'project_francois1er.jpg',
        title: 'François 1er',
        description: "Intranet d’une entreprise de restauration immobilière",
        tasks: [
            'Integration front'
        ],
        technologies: [
            'html',
            'css',
            'Jquery'
        ]
    },
    {
        img: 'project_landing_page.jpg',
        title: 'Grand Large',
        description: "Landing page d'un palais des congrès de Saint-Malo",
        tasks: [
            'Integration front'
        ],
        technologies: [
            'html',
            'css',
            'Jquery'
        ]
    },
    {
        img: 'project_bentley.jpg',
        title: 'Bentley',
        description: "Site vitrine d’un bar de nuit lounge",
        tasks: [
            'Integration front et back',
        ],
        technologies: [
            'html',
            'css',
            'Jquery',
            'PHP',
            'wordpress'
        ]
    },
];

export default projectsListFr;