import React from 'react';
import { useIntl, Link} from "gatsby-plugin-intl";
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import anim from '../images/icon-hp.svg';
import '../styles/home.scss';

gsap.registerPlugin(ScrollToPlugin);

const Home = ({containerRef}) => {
    const intl = useIntl();
    const [XLDevice, setXLDevice] = React.useState(false);
     React.useEffect(() => {
        if(window.matchMedia('(min-width: 990px)').matches){
          setXLDevice(true);
        } else {
          setXLDevice(false);
        }
        
    }, [XLDevice]);
    return(
        <div className="container index home">
            <div className="title">
                <h1>Aurélie Hautbois</h1>
                <h2>{intl.formatMessage({ id: "home.role" })}</h2>
                <Link 
                    to="/#skills" 
                    title={intl.formatMessage({ id: "home.btn_discover_title" })} 
                    className="btn"
                    onClick={() => {
                        if(XLDevice){
                            gsap.to(window, {
                                duration: 0.5,
                                scrollTo: Math.round(containerRef.current.offsetHeight / 4)
                            });
                        }
                        
                    }}
                    >
                    {intl.formatMessage({ id: "home.btn_discover" })}
                </Link>
            </div>
            <div className="animation">
                <img src={anim} alt={intl.formatMessage({ id: "home.animation_alt" })}/>
            </div>
        </div>
    )
};

export default Home;