import React from 'react';
import SliderProjects from './sliderProject/sliderProject';
import projectsListFr from '../data/projectsListFr';
import projectsListEn from '../data/projectsListEn';
import CardProject from './cardProject';
import { useIntl } from "gatsby-plugin-intl";

const Projects = () => {
    const intl = useIntl();
    let projectsArr;
    const [XLDevice, setXLDevice] = React.useState(false);

    if (intl.locale === 'fr') {
        projectsArr = projectsListFr;
    } else {
        projectsArr = projectsListEn;
    }

    React.useEffect(() => {
        if(window.matchMedia('(min-width: 990px)').matches){
          setXLDevice(true);
        } else {
          setXLDevice(false);
        }
        
    }, [XLDevice]);

    return(
        <div className={'container index project-page'}>
            <h1>{intl.formatMessage({ id: "project_page" })}</h1>
            {
                XLDevice ?
                <SliderProjects data={projectsArr} />
                : <div className="container-cards"> {
                        projectsArr.map((project, i) => {
                        return(
                            <CardProject key={i} slide={project} />
                        ) 
                      })
                  }
                  </div>
            }
            
        </div>
    )
}

export default Projects;