import React from 'react';
import '../styles/arrows.scss';

const Arrow = ({direction, handleClick, content}) => {
    return(
        <button
            onClick={handleClick}
            className={`arrow ${direction === 'right' ? 'arrow-right':'arrow-left'}`}
        >
        {content}
        </button>
    )
}

export default Arrow;