import React from 'react';
import Arrow from '../arrow';
import Dots from '../dots';
import ProjectItem from './ProjectItem';
import '../../styles/projects.scss';

const SliderProjects = (props) => {

    const [activeIndex, setActiveIndex] = React.useState(0);

    const nextSlide = () => {
        if (activeIndex === props.data.length - 1) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    };

    const prevSlide = () => {
        if (activeIndex === 0) {
            setActiveIndex(props.data.length - 1);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    };

    const activeSlide = (dotIndex) => {
        setActiveIndex(parseInt(dotIndex));
    };

    return(
        <div className="container-slider-project">
            <div className="slider">
                {props.data.map((slide, i) => <ProjectItem key={i} index={i} arr={props.data} slide={slide} activeIndex={activeIndex} show={activeIndex === i}/>)}
                <div className="container-nav">   
                    <Arrow direction="left" handleClick={prevSlide} content={'prev'} />
                    <Dots slides={props.data} activeIndex={activeIndex} activeSlide={(dotIndex) => activeSlide(dotIndex)}/>
                    <Arrow direction="right" handleClick={nextSlide} content={'next'} />
                </div>
            </div>
        </div>
    )
}

export default SliderProjects;