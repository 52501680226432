import React from 'react';
import '../styles/dots.scss';

const Dots = ({ slides, activeIndex, activeSlide }) => {
    return(
        <div className="container-dots">
            {slides.map((slide, i) => (
                <button 
                    key={i} 
                    className={`dot ${activeIndex === i ? 'active' : ''} ${(activeIndex - 1) === i  ? 'dot-prev' : ''} ${activeIndex === 0 ? (slides.length - 1) === i ? 'dot-prev' : '' : ''} ${(activeIndex + 1) === i  ? 'dot-next' : ''} ${activeIndex === (slides.length - 1) ? i === 0 ? 'dot-next' : '' : ''}`} 
                    onClick={(e) => activeSlide(e.target.dataset.index)}
                    data-index={i}
                >
                </button>
            ))}
        </div>
    )
}

export default Dots;