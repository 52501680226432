const projectsListEn = [
    {
        img: 'project_emotors.jpg',
        title: 'E-motors',
        description: "Car dealer eCommerce website",
        tasks: [
            'Website maintenance and optimization',
        ],
        technologies: [
            'html',
            'Sass',
            'Bootstrap',
            'Jquery',
            'Twig'
        ]
    },
    {
        img: 'project_borne.jpg',
        title: 'Interactive kiosk',
        description: "Application for ordering cars",
        tasks: [
            'Front end integration',
        ],
        technologies: [
            'html',
            'Sass',
            'Bootstrap',
            'Angular'
        ]
    },
    {
        img: 'project_minisite.jpg',
        title: 'Generator websites templates',
        description: "Showcase site of generator websites templates",
        tasks: [
            'Integration front',
        ],
        technologies: [
            'html',
            'Sass',
            'Bootstrap',
            'Gatsby'
        ]
    },
    {
        img: 'project_francois1er.jpg',
        title: 'François 1er',
        description: "Intranet of a real estate company",
        tasks: [
            'Front end integration'
        ],
        technologies: [
            'html',
            'css',
            'Jquery'
        ]
    },
    {
        img: 'project_landing_page.jpg',
        title: 'Grand Large',
        description: "Landing page of the Saint-Malo convention center",
        tasks: [
            'Front end integration'
        ],
        technologies: [
            'html',
            'css',
            'Jquery'
        ]
    },
    {
        img: 'project_bentley.jpg',
        title: 'Bentley',
        description: "Showcase site of a lounge bar",
        tasks: [
            'Front end and back end integration'
        ],
        technologies: [
            'html',
            'css',
            'Jquery',
            'PHP',
            'wordpress'
        ]
    },
];

export default projectsListEn;