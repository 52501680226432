import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from '../components/home';
import Skills from '../components/skills';
import Projects from '../components/projects';
import About from '../components/about';
import Contact from '../components/contact';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const IndexPage = (props) => {
  const shapeA = React.createRef();
  const shapeB = React.createRef();
  const shapeC = React.createRef();
  const shapeD = React.createRef();
  const shapeE = React.createRef();
  const container = React.createRef();


  React.useEffect(() => {
      ScrollTrigger.matchMedia({
        "(min-width:990px)": function () {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: container.current,
                start: "top top",
                end: "+=100%",
                toggleActions: "restart pause reverse pause",
                scrub: 1,
                pin: true,
                markers: false,
                snap: 1 / 4
              },
            })
            // .from(shapeA.current, {
            //   onComplete: () => console.log("present"),
            // })
            .to(shapeA.current, {
              scale: 6,
              autoAlpha: 0,
              delay: 0.3,
              duration: 0.5,
              zIndex: 1,
              // onComplete: () => console.log("invisble"),
            })

            .to(shapeB.current, {
              scale: 6,
              autoAlpha: 0,
              delay: 0.3,
              duration: 0.5,
              zIndex: 1,
            })

            .to(shapeC.current, {
              scale: 6,
              autoAlpha: 0,
              delay: 0.3,
              duration: 0.5,
              zIndex: 1,
            })

            .to(shapeD.current, {
              scale: 6,
              autoAlpha: 0,
              delay: 0.3,
              duration: 0.5,
              zIndex: 1,
            })
        },
      })
  }, [container, shapeA, shapeB, shapeC, shapeD]);

  return(
    <Layout location={props.location} containerRef={shapeA}>
      <SEO title="Portfolio" />
      <div className="wrapper" ref={container}>
          <section className={`box a ${container.current}`} id="home" ref={shapeA}>
            <Home containerRef={shapeA} containerNext={shapeB}/>
          </section>
          <section className="box b" id="skills" ref={shapeB}>
            <Skills  containerRef={shapeA}/>
          </section>
          <section className="box c" id="projects" ref={shapeC}>
            <Projects />
          </section>
          <section className="box d" id="about" ref={shapeD}>
            <About />
          </section>
          <section className="box e" id="contact" ref={shapeE}>
            <Contact />
          </section>
      </div>
    </Layout>
  
  ) 
}

export default IndexPage;

