const skillsArrFr = [
    {
        titlePartOne: 'Integration',
        titlePartTwo: 'Responsive',
        skills: [
            'html',
            'css',
            'js'
        ]
    },
    {
        titlePartOne: 'Frameworks',
        titlePartTwo: 'CSS',
        skills: [
            'Sass',
            'Bootstrap'
        ]
    },
    {
        titlePartOne: 'Modern javascript &',
        titlePartTwo: 'Frameworks',
        skills: [
            'ES6+',
            'Typescript',
            'React',
            'Angular',
            'Gatsby'
        ]
    },
    {
        titlePartOne: 'Content',
        titlePartTwo: 'Management System',
        skills: [
            'Wordpress',
            'Drupal',
        ]
    },
    {
        titlePartOne: 'Data',
        titlePartTwo: 'Fetching',
        skills: [
            'RESTFUL API',
            'GRAPHQL',
        ]
    },
    {
        titlePartOne: 'Moteur de',
        titlePartTwo: 'Templating',
        skills: [
            'Twig',
        ]
    },
    {
        titlePartOne: 'Developpement',
        titlePartTwo: 'Backend',
        skills: [
            'php',
            'node js',
            'express',
            'python'
        ]
    },
    {
        titlePartOne: 'Gestion de',
        titlePartTwo: 'base de données',
        skills: [
            'MySQL',
            'MongoDB',
        ]
    },
    {
        titlePartOne: 'Gestionnaire de',
        titlePartTwo: 'version',
        skills: [
            'Git',
            'Github',
            'Gitlab'
        ]
    }, 
    {
        titlePartOne: 'Container',
        titlePartTwo: 'Manager',
        skills: [
            'docker',
        ]
    },
    {
        titlePartOne: 'Package manager &',
        titlePartTwo: 'bundler',
        skills: [
            'NPM',
            'yarn',
            'webpack',
            'gulp'
        ]
    },
    {
        titlePartOne: 'Gestion de',
        titlePartTwo: 'projet',
        skills: [
            'méthodologie agile'
        ]
    },
    {
        titlePartOne: 'PAO',
        titlePartTwo: '',
        skills: [
            'photoshop',
            'illustrator',
            'affinity designer',
            'XD',
            'invision studio',
            'figma'
        ]
    },
    {
        titlePartOne: 'Motion design &',
        titlePartTwo: 'montage vidéo',
        skills: [
            'After effects',
            'premiere pro',
            'Final cut pro',
            'Avid Media composer',
        ]
    },
];

export default skillsArrFr;