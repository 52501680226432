import React from 'react';
import { useFormik } from 'formik';
import { useIntl } from 'gatsby-plugin-intl';
import Modal from './modal';
import '../styles/contact.scss';


const Contact = () => {
    const intl = useIntl();
    const [openModal, setOpenModal] = React.useState(false);
    const [error, setError] = React.useState(false);
    let requiredField;
    let nameError;

    React.useEffect(() => {
        if(openModal){
            document.getElementsByTagName("body")[0].style = 'overflow: hidden';
        } else {
            document.getElementsByTagName("body")[0].style = 'overflow-y: scroll';
        }
        
    }, [openModal]);

    if(intl.locale === 'fr'){
      requiredField = "Champ requis";
      nameError = "Doit comporter au moins 2 caractères";
    } else {
      requiredField = "Required";
      nameError = "Must be at least 2 characters";
    }

    const validate = values => {
       const errors = {};
       if (!values.name) {
         errors.name = requiredField;
       } else if (values.name.length <= 1) {
         errors.name = nameError;
       }

       if (!values.email) {
         errors.email = requiredField;
       } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
         errors.email = 'Invalid email address';
       }

      if (!values.message) {
        errors.message = requiredField;
      } 

      if (!values.rgpd) {
        errors.rgpd = requiredField;
      }
 
       return errors;
     };

    const formik = useFormik({
      initialValues: {
        name: "",
        email: "",
        message: "",
        rgpd: false,
      },
      validate,
      onSubmit: (values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact-form", ...values })
        })
        .then(() => {
          setError(false);
          setOpenModal(true);
          actions.resetForm();
        })
        .catch(() => {
          setOpenModal(true);
          setError(true);
        })
        .finally(() => actions.setSubmitting(false))
      },
    });
    const encode = (data) => {
      return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    };
    return (
      <div className="container index contact">
        <div className="infos">
          <h1>Contact</h1>
          <p>{intl.formatMessage({ id: "contact.phone" })}</p>
          <a href="tel:+33768313174" className="tel">+33 07 68 31 31 74</a>
          <p>{intl.formatMessage({ id: "contact.email" })}</p>
          <a href="mailto:aurelie.hautbois@gmail.com" className="mail">
            aurelie.hautbois@gmail.com
          </a>
        </div>
        <form onSubmit={formik.handleSubmit} name="contact-form" data-netlify={true}>
          <div className="row">
            <input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
              aria-label="Nom"
              placeholder={intl.formatMessage({ id: "contact.name" })}
            />
            {formik.errors.name ? <div className="error">{formik.errors.name}</div> : null}
          </div>
          <div className="row">
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              aria-label="Email"
              placeholder={intl.formatMessage({ id: "contact.email" })}
            />
            {formik.errors.email ? <div className="error">{formik.errors.email}</div> : null}
          </div>
          <div className="row">
            <textarea
              id="message"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
              aria-label="Message"
              placeholder="Message"
            />
            {formik.errors.message ? <div className="error">{formik.errors.message}</div> : null}
          </div>
          <div className="row container-rgpd">
            <input
              id="rgpd"
              name="rgpd"
              type="checkbox"
              onChange={formik.handleChange}
              value={formik.values.rgpd}
            />
            <label htmlFor="rgpd">
              {intl.formatMessage({ id: "contact.privacy" })}
            </label>
            {formik.errors.rgpd ? <div className="error">{formik.errors.rgpd}</div> : null}
          </div>
          <button type="submit" className="btn">{intl.formatMessage({ id: "contact.send" })}</button>
        </form>
        {openModal && 
          <Modal showFunc={setOpenModal}>
            <div className="title-modal">
                <button className="cross" onClick={() => setOpenModal(false)} aria-label="show menu"></button>
            </div>
            <div className="body-modal-contact">
              {error ? 
                <React.Fragment>
                  <h2>{intl.formatMessage({ id: "contact.error_title" })}</h2>
                  <p>{intl.formatMessage({ id: "contact.error_text" })}</p>
                </React.Fragment>
                : <React.Fragment>
                  <h2>{intl.formatMessage({ id: "contact.send_title" })}</h2>
                  <p>{intl.formatMessage({ id: "contact.send_text" })}</p>
                </React.Fragment>
              }
            </div>
          </Modal>
        }
      </div>
    )
};

export default Contact;