import React from 'react';

const ItemSkills = (props) => {

    const [shouldRender, setRender] = React.useState(props.show);

    React.useEffect(() => {
        if (props.show) setRender(true);
    }, [props.show]);

    const onAnimationEnd = () => {
        if (!props.show) setRender(false);
    };

    return(
        shouldRender && (
            <div className="slide-content">
                <div className="overlay card-skills" style={{animation: `${props.show ? "slideToBottomIn" : "slideToBottomOut"} 1s ease` }}
                onAnimationEnd={onAnimationEnd}>
                    <h2>{props.slide.titlePartOne} <span>{props.slide.titlePartTwo}</span></h2>
                    <ul>
                        {props.slide.skills.map((skill, i) => {
                            return(
                                
                                <li key={i}>{skill}</li>
                            
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    )
}

export default ItemSkills;