import React from 'react';
import Arrow from '../arrow';
import Dots from '../dots';
import ItemSkills from './itemSkills';
import { useIntl} from "gatsby-plugin-intl";

const SliderSkills = (props) => {
    const intl = useIntl();
    let newArr;

    if (window.matchMedia('(min-width: 1150px)').matches) {
        newArr = props.data.reduce((accumulator, currentValue, currentIndex, array) => {
            if (currentIndex % 4 === 0)
                accumulator.push(array.slice(currentIndex, currentIndex + 4));
            return accumulator;
        }, []);
    } else {
        newArr = props.data.reduce((accumulator, currentValue, currentIndex, array) => {
            if (currentIndex % 2 === 0)
                accumulator.push(array.slice(currentIndex, currentIndex + 2));
            return accumulator;
        }, []);
    }


    const [activeIndex, setActiveIndex] = React.useState(0);

    const nextSlide = () => {
        if (activeIndex === newArr.length - 1) {
            setActiveIndex(0);
        } else {
            setActiveIndex(activeIndex + 1);
        }
    };

    const prevSlide = () => {
        if (activeIndex === 0) {
            setActiveIndex(newArr.length - 1);
        } else {
            setActiveIndex(activeIndex - 1);
        }
    };

    const activeSlide = (dotIndex) => {
        setActiveIndex(parseInt(dotIndex));
    };

    return(
        <div className="container-slider">
            {
                    newArr.map((slide, slideIndex) => {
                    return(
                        <div key={slideIndex} className={`slide ${activeIndex === slideIndex ? 'active' : ''}`}>
                            {
                                Object.values(slide).map((card, cardIndex) => {
                                    return(
                                        <ItemSkills key={cardIndex} show={activeIndex === slideIndex}  slide={card}/>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
            <Arrow direction="left" handleClick={prevSlide} content={intl.formatMessage({ id: "prev" })} />
            <Arrow direction="right" handleClick={nextSlide} content={intl.formatMessage({ id: "next" })} />
            <Dots slides={newArr} activeIndex={activeIndex} activeSlide={(dotIndex) => activeSlide(dotIndex)}/>
        </div>
    )
}

export default SliderSkills;