import React from 'react';
import '../styles/skills.scss';
import SliderSkills from './slider-skills/sliderSkills';
import skillsArrFr from '../data/skillsFr';
import skillsArrEn from '../data/skillsEn';
import CardSkills from './cardSkills';
import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useIntl, Link } from "gatsby-plugin-intl";

gsap.registerPlugin(ScrollToPlugin);

const Skills = ({containerRef}) => {
    const intl = useIntl();
    let skillsArr;
    const [XLDevice, setXLDevice] = React.useState(false);

    if(intl.locale === 'fr'){
      skillsArr = skillsArrFr;
    } else {
      skillsArr = skillsArrEn;
    }

    React.useEffect(() => {
        if(window.matchMedia('(min-width: 990px)').matches){
          setXLDevice(true);
        } else {
          setXLDevice(false);
        }
        
    }, [XLDevice]);

    return (
      <div className="container index skills-page">
        <div className="title">
            <h1>{intl.formatMessage({ id: "skills_page" })}</h1>
            <Link 
                to="/#project" 
                title={intl.formatMessage({ id: "go_projects" })} 
                className="btn"
                onClick={() => {
                    if(XLDevice){
                      gsap.to(window, {
                          duration: 1,
                          scrollTo: Math.round(containerRef.current.offsetHeight / 2)
                      });
                    }
                }}
                >
              {intl.formatMessage({ id: "go_projects" })}
            </Link>
        </div>
        {
          XLDevice ? 
          <SliderSkills data={skillsArr}/>
          :  <div className="container-cards"> {
                skillsArr.map((skill, i) => {
                return(
                    <CardSkills key={i} skillsArr={skill} />
                ) 
              })
          }
          </div>
          
        }
        
      </div>
    )
}

export default Skills;