import React from 'react';

const CardSkills = (props) => {
    return(
        <div className="card-skills">
            <h2>{props.skillsArr.titlePartOne} <span>{props.skillsArr.titlePartTwo}</span></h2>
            <ul>
                {props.skillsArr.skills.map((skill, i) => {
                    return(  
                        <li key={i}>{skill}</li>   
                    )
                })}
            </ul>
        </div>
    )
}

export default CardSkills;