import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

const ProjectItem = (props) => {
    const [shouldRender, setRender] = React.useState(props.show);

    React.useEffect(() => {
        if (props.show) setRender(true);
    }, [props.activeIndex, props.show]);

    const onAnimationEnd = () => {
        if (!props.show) setRender(false);
    };

    const data = useStaticQuery(graphql`
        query imgsQuery {
            allFile(filter: {extension: {regex: "/(jpg)|(png)/"}, relativeDirectory: {eq: "projects"}}) {
                edges {
                  node {
                    childImageSharp {
                        fluid(maxWidth: 500, sizes: "500px, 200px") {
                            originalName
                            srcSetWebp
                            srcSet
                            sizes
                        }
                    }
                  }
                }
            }
        }
    `);
    const imgSlide = data.allFile.edges.filter(item => item.node.childImageSharp.fluid.originalName === props.slide.img);

    return(
        <div  
            className={`slide-content ${props.activeIndex === props.index ? 'selected' : ''} ${(props.activeIndex - 1) === props.index  ? 'item-prev' : ''} ${props.activeIndex === 0 ? (props.arr.length - 1) === props.index ? 'item-prev' : '' : ''} ${(props.activeIndex + 1) === props.index  ? 'item-next' : ''} ${props.activeIndex === (props.arr.length - 1) ? props.index === 0 ? 'item-next' : '' : ''}`} >
            <picture>
                <source srcSet={imgSlide[0].node.childImageSharp.fluid.srcSetWebp} type="image/webp" />
                <source srcSet={imgSlide[0].node.childImageSharp.fluid.srcSet} media="(max-width: 300px)"/>
                <img src={imgSlide[0].node.childImageSharp.fluid.src} alt={props.slide.title}  />
            </picture>
            {shouldRender &&
                <div className="intro" style={{animation: `${props.show ? "fadeIn" : "fadeOut"} 0.8s ease` }}
                    onAnimationEnd={onAnimationEnd}>
                    <h2>{props.slide.title}</h2>
                    <p>{props.slide.description}</p>
                    <p className="mission">Missions:</p>
                    <ul className="tasks">
                        {props.slide.tasks.map((skill, i) => <li key={i}>- {skill}</li>)}
                    </ul>
                    <ul className="technologies">
                        {props.slide.technologies.map((tech, index) => <li key={index}>{tech}</li>)}
                    </ul>
                </div>
            }
        </div>
    )
}



export default ProjectItem;