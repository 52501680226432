import React from 'react';
import { useIntl } from "gatsby-plugin-intl";
import { graphql, useStaticQuery } from "gatsby";
import resumeFr from '../images/cv_aurelie_fr.pdf';
import '../styles/about.scss';


const About = () => {
    const intl = useIntl();
    const data = useStaticQuery(graphql`
        query imgProfile {
            file(relativePath: {eq: "img_profile.png"}) {
                childImageSharp {
                  fluid {
                    srcSetWebp
                    srcSet
                    src
                  }
                }
            }
        }
    `);
    return(
        <div className="container index about">
            <div className="container-img">
                <picture>
                      <source srcSet={data.file.childImageSharp.fluid.srcSetWebp} type="image/webp" />
                      <source srcSet={data.file.childImageSharp.fluid.srcSet} media="(max-width: 500px)"/>
                      <img src={data.file.childImageSharp.fluid.src} alt="portrait aurelie hautbois"  />
                  </picture>
            </div>
            <div className="intro">
                <h1>Aurélie hautbois</h1>
                <p className="role">{intl.formatMessage({ id: "home.role" })}</p>
                <p className="resume">{intl.formatMessage({ id: "resume"})}</p>
                <a className="btn" href={resumeFr} target="_blank" title={intl.formatMessage({ id: "btn_resume"})}>
                {intl.formatMessage({ id: "btn_resume"})}</a>
            </div>
        </div>
    )
}

export default About;