import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

const CardProject = (props) => {
    const data = useStaticQuery(graphql`
        query imgsCardQuery {
          allFile(filter: {extension: {regex: "/(jpg)|(png)/"}, relativeDirectory: {eq: "projects"}}) {
              edges {
                node {
                  childImageSharp {
                      fluid(maxWidth: 250) {
                          originalName
                          srcSetWebp
                          srcSet
                      }
                  }
                }
              }
          }
        }
    `);
    const imgSlide = data.allFile.edges.filter(item => item.node.childImageSharp.fluid.originalName === props.slide.img);
    return(
        <div className="card-project">
            <div className="container-img">
              <picture>
                  <source srcSet={imgSlide[0].node.childImageSharp.fluid.srcSetWebp} type="image/webp" />
                  <source srcSet={imgSlide[0].node.childImageSharp.fluid.srcSet} media="(max-width: 250px)"/>
                  <img src={imgSlide[0].node.childImageSharp.fluid.src} alt={props.slide.title}  />
              </picture>
            </div>
            <div className="intro">
                <h2>{props.slide.title}</h2>
                <p>{props.slide.description}</p>
                <p className="mission">Missions:</p>
                <ul className="tasks">
                    {props.slide.tasks.map((skill, i) => <li key={i}>- {skill}</li>)}
                </ul>
                <ul className="technologies">
                    {props.slide.technologies.map((tech, index) => <li key={index}>{tech}</li>)}
                </ul>
            </div>
        </div>
    )
}


export default CardProject;